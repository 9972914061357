import _extends from "@babel/runtime/helpers/extends";
import { Fragment, useCallback } from "react";
import { bannerFooterInlineStyle, bannerFooterInlineStyleContainer } from ".";
import { useBanner } from "../../contexts";
import { getOtherOptionsFromWindow, jsxJoin } from "../../utils";
import { useBannerLegalLinks, useBannerRefToRenderedHeight } from "../../hooks";
import { BannerFooterLink, BannerTooltip } from ".";

var BannerFooter = function BannerFooter() {
  var banner = useBanner();
  var isTcf = banner.isTcf,
      type = banner.layout.type,
      legal = banner.legal,
      _banner$footerDesign = banner.footerDesign,
      poweredByLink = _banner$footerDesign.poweredByLink,
      linkTarget = _banner$footerDesign.linkTarget,
      individualPrivacyOpen = banner.individualPrivacyOpen,
      updateIndividualPrivacyOpen = banner.updateIndividualPrivacyOpen,
      poweredLink = banner.poweredLink,
      activeAction = banner.activeAction,
      isConsentRecord = banner.isConsentRecord,
      onClose = banner.onClose;

  var _getOtherOptionsFromW = getOtherOptionsFromWindow(),
      isPro = _getOtherOptionsFromW.isPro,
      _getOtherOptionsFromW2 = _getOtherOptionsFromW.bannerI18n,
      close = _getOtherOptionsFromW2.close,
      closeWithoutSaving = _getOtherOptionsFromW2.closeWithoutSaving,
      tcf = _getOtherOptionsFromW2.tcf,
      affiliate = _getOtherOptionsFromW.affiliate;

  var ref = useBannerRefToRenderedHeight("footerContainer");
  var handleTcfVendorList = process.env.IS_TCF === "1" && isTcf ? useCallback(function (e) {
    updateIndividualPrivacyOpen(true);
    e.preventDefault();
  }, [updateIndividualPrivacyOpen]) : undefined;
  var handleClose = useCallback(function (e) {
    onClose();
    e.preventDefault();
  }, [onClose]);
  var targetAttributes = linkTarget === "_blank" ? {
    target: "_blank",
    rel: "noopener"
  } : {};

  var _useBannerLegalLinks = useBannerLegalLinks(legal),
      linkPrivacyPolicy = _useBannerLegalLinks.linkPrivacyPolicy,
      linkImprint = _useBannerLegalLinks.linkImprint;

  var links = [// Row 1
  [(!!activeAction || isConsentRecord) && h(BannerFooterLink, {
    href: "#",
    onClick: handleClose,
    key: "close"
  }, h("strong", null, activeAction === "change" ? closeWithoutSaving : close)), process.env.IS_TCF === "1" && isTcf && !individualPrivacyOpen && h(BannerFooterLink, {
    href: "#",
    onClick: handleTcfVendorList,
    key: "vendorList"
  }, tcf.vendorList), linkPrivacyPolicy && h(BannerFooterLink, _extends({
    href: linkPrivacyPolicy.url
  }, targetAttributes, {
    key: "privacyPolicy"
  }), linkPrivacyPolicy.label), linkImprint && h(BannerFooterLink, _extends({
    href: linkImprint.url
  }, targetAttributes, {
    key: "imprint"
  }), linkImprint.label)].filter(Boolean), // Row 2
  [] // Nothing yet
  ];

  if (!!poweredLink && (poweredByLink || !isPro)) {
    links[type === "banner" ? 0 : 1].push(h(BannerFooterLink, {
      href: affiliate ? affiliate.link : poweredLink.href,
      target: poweredLink.target,
      key: "powered-by"
    }, h("span", {
      dangerouslySetInnerHTML: {
        __html: poweredLink.innerHTML
      }
    }), affiliate && h(BannerTooltip, {
      title: affiliate.description
    }, " ", affiliate.labelBehind)));
  }

  return h("div", _extends({
    ref: ref
  }, bannerFooterInlineStyleContainer(banner)), h("div", bannerFooterInlineStyle(banner), jsxJoin(links.map(function (linksRow) {
    return linksRow.length ? h(Fragment, {
      key: linksRow[0].key
    }, jsxJoin(linksRow, h(Fragment, null, " \u2022 "))) : null;
  }), h("br", null))));
};

export { BannerFooter };