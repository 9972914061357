import _extends from "@babel/runtime/helpers/extends";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useState, useLayoutEffect, useEffect } from "react";
import { BannerContent, bannerOverlayInlineStyle, bannerOverlayInlineStyleAlignment, bannerContentInlineStyleInner } from ".";
import { useBanner } from "../../contexts";
import { useRestrictScrollWhenVisible, useResetableAnimation, usePlainCss, useBannerActionType } from "../../hooks";
import { AnimatedCss } from "..";
import { getOtherOptionsFromWindow } from "../../utils";
import { isHiddenDueLegal, dispatchResizeEvent } from "../../others";

var Banner = function Banner() {
  var banner = useBanner();

  var _getOtherOptionsFromW = getOtherOptionsFromWindow(),
      isPro = _getOtherOptionsFromW.isPro;

  var _banner$layout = banner.layout,
      animationIn = _banner$layout.animationIn,
      animationInDuration = _banner$layout.animationInDuration,
      animationOut = _banner$layout.animationOut,
      animationOutDuration = _banner$layout.animationOutDuration,
      overlay = _banner$layout.overlay,
      overlayBlur = _banner$layout.overlayBlur,
      _banner$customCss = banner.customCss,
      antiAdBlocker = _banner$customCss.antiAdBlocker,
      css = _banner$customCss.css,
      visible = banner.visible,
      animationVisible = banner.animationVisible,
      activeAction = banner.activeAction,
      skipOverlay = banner.skipOverlay,
      gotHiddenDueLegal = banner.gotHiddenDueLegal,
      pageRequestUuid4 = banner.pageRequestUuid4,
      individualPrivacyOpen = banner.individualPrivacyOpen;

  var _useResetableAnimatio = useResetableAnimation(animationIn, animationInDuration),
      _useResetableAnimatio2 = _slicedToArray(_useResetableAnimatio, 2),
      stateAnimationIn = _useResetableAnimatio2[0],
      stateAnimationInDuration = _useResetableAnimatio2[1];

  var _useResetableAnimatio3 = useResetableAnimation(animationOut === "none" ? "fadeOut" : animationOut, animationOut === "none" ? 0 : animationOutDuration),
      _useResetableAnimatio4 = _slicedToArray(_useResetableAnimatio3, 2),
      stateAnimationOut = _useResetableAnimatio4[0],
      stateAnimationOutDuration = _useResetableAnimatio4[1];

  var hiddenDueLegal = isHiddenDueLegal(banner);
  var show = visible && !hiddenDueLegal || !!activeAction;
  usePlainCss(antiAdBlocker ? css : "");
  usePlainCss("#".concat(pageRequestUuid4, ", #").concat(pageRequestUuid4, " * {box-sizing: border-box;}")); // Blur effect

  if (isPro) {
    /* Silence is golden... Until you have PRO Version! */
  }

  useRestrictScrollWhenVisible(show && overlay && animationVisible); // Listen to window resizes and resize the content automatically

  var _useState = useState(0),
      _useState2 = _slicedToArray(_useState, 2),
      setInvalidate = _useState2[1];

  var updateSize = function updateSize() {
    return setInvalidate(+new Date());
  };

  useLayoutEffect(function () {
    window.addEventListener("resize", updateSize);
    return function () {
      return window.removeEventListener("resize", updateSize);
    };
  }, []);
  useLayoutEffect(function () {
    if (show) {
      dispatchResizeEvent(1000);
    }
  }, [show, individualPrivacyOpen]); // When changes to the legal settings are done, make the banner visible or hide

  gotHiddenDueLegal && useEffect(function () {
    gotHiddenDueLegal(hiddenDueLegal);
  }, [hiddenDueLegal]);
  useBannerActionType();

  if (!show) {
    return null;
  }

  var content = h("div", bannerOverlayInlineStyleAlignment(banner), h(AnimatedCss, _extends({
    animationIn: stateAnimationIn,
    animationInDuration: stateAnimationInDuration,
    animationOut: stateAnimationOut,
    animationOutDuration: stateAnimationOutDuration,
    isVisible: animationVisible
  }, bannerContentInlineStyleInner(banner)), h(BannerContent, null)));
  return skipOverlay ? content : h("div", _extends({
    id: pageRequestUuid4
  }, bannerOverlayInlineStyle(banner)), content);
};

export { Banner };